const hu = {
  more: `Mégtöbb`,
  noresults: `Nincs találat.`,
  hungarian: `Magyar`,
  english: `English`,
  home: `Főoldal`,
  search: `Keresés`,
  mytickets: `Jegyeim`,
  otherlang: `English`,
  buyticket: `Jegyvásárlás`,
  information: `Információk`,
  info_desc: `Itt megtalálsz minden fontos tudnivalót`,
  info_faq: `GyIK`,
  info_terms: `ÁSZF`,
  info_privacy: `Adatvédelmi Szabályzat`,
  info_career: `Karrier`,
  contact: `Kapcsolat`,
  fororgs: `Szervezőknek`,
  org_desc: `Online jegy, elővétel, klubok, fesztiválok, rendezvényszervezők, zenekarok számára`,
  interested: `Érdekel`,
  providers: `Szolgáltatók`,
  seatneed: `A jegyvásárlás helyfoglalás alapján történik.`,
  reserve: `Helyfoglalás`,
  buy: `Megveszem`,
  reserveticket: `Lefoglalom`,
  coupon: `Kuponkód`,
  havecoupon: `Kuponkódom van`,
  couponcode: `Kuponkód`,
  code: `Kód`,
  havecode: `Kód megadása`,
  seats: `Kiválasztott helyek`,
  standings: `Állóhelyek`,
  personalinfo: `Személyes adatok`,
  queue: `Várólista`,
  summarypay: `Rendelés összesítő`,
  summarynopay: `Rendelés összesítő`,
  name: `Név`,
  email: `Email cím`,
  emailconfirm: `Email cím mégegyszer`,
  phone: `Telefonszám`,
  zip: `Irányítószám`,
  city: `Település`,
  address: `Pontos cím`,
  diffinvoce: `Eltérő számlázási adatok`,
  invname: `Számlázási név`,
  invvat: `Adószám`,
  dateentryformat: `mm/dd/yyyy`,
  ordercomment: `Megjegyzés a megrendeléssel kapcsolatban`,
  password: `Jelszó`,
  passagain: `Jelszó mégegyszer`,
  cancel: `Mégsem`,
  next: `Tovább`,
  shipping: `Szállítás`,
  receivemethod: `Hogyan szeretnéd átvenni?`,
  post: `Kézbesítés`,
  eticket: `Elektronikus jegy`,
  inlandonly: `Kézbesítés csak belföldön lehetséges.`,
  eticketformats: `Letölthető PDF, Apple Wallet és Google Wallet formátumokban`,
  payment: `Fizetés`,
  paymentmethod: `Hogyan szeretnél fizetni?`,
  cardterms: `Tájékoztató`,
  terms: `Feltételek`,
  acceptTerms: `Elfogadom a feltételeket`,
  back: `Vissza`,
  alltogether: `Összesen:`,
  pay: `Fizetés`,
  momentplease: `Kis türelmet kérünk...`,
  highdemand: `Az eseményt pillanatnyilag kirobbanó érdeklődés övezi. Amint teljesíthető a kérésed, továbbítunk a kosárhoz.`,
  afewminutes: `A leterheltségtől függően ez pár percig is eltarthat.`,
  queuelocation: `Aktuális helyed a sorban`,
  timeout: `Időtúllépés`,
  timesup: `Inaktivitás miatt a kosaradat fel kellett szabadítanunk egy másik vásárlónak.`,
  reload: `Újratöltés`,
  close: `Bezárás`,
  waitpay: `Várakozás a tranzakció befejezésére partnerünknél...`,
  plsnoreload: `Nem szükséges újratöltened az oldalt.`,
  paysuccess: `Sikeres vásárlás!`,
  paysuccessdesc: `A jegyeidet és a számlát elküldtük az email címedre.`,
  paysuccessthanks: `Köszönjük szépen! Érezd jól magad!`,
  date: `Dátum`,
  orderid: `Rendelésszám`,
  pay_ref: `Fizetés referenciaszáma`,
  pay_met: `Fizetési mód`,
  totickets: `Tovább a jegyeimhez`,
  payfailure: `Sikertelen tranzakció`,
  payfailuredesc: `Kérlek, ellenőrizd a megadott adatok helyességét. Amennyiben mindent helyesen adtál meg, a visszautasítás okának kivizsgálásáért kérlek lépj kapcsolatba a kártyát kibocsátó bankkal.`,
  payfailuredesc2: `Ha úgy gondolod, hogy valami a Tixánál csúszhatott félre, kérlek keresd ügyfélszolgálatunkat.`,
  lostmypass: `Elfelejtettem a jelszavam`,
  confirm: `Megerősítés`,
  sgwrong: `Valami nem stimmelt`,
  idplink: `Korábban még nem használtad a Facebook-os bejelentkezést, de már regisztráltál nálunk. Kérlek add meg a korábbi tixás jelszavad a folytatáshoz.`,
  restore: `Visszaállítás`,
  cantrestore: `Nem sikerült a visszaállítás.`,
  newpass: `Új jelszó`,
  newpass2: `Új jelszó mégegyszer`,
  restorecode: `Visszaállító kód`,
  restoreinst: `A fiókod visszaállításához add meg a kapott kódot.`,
  uforgot: `Elfelejtetted a jelszavad?`,
  nofbsosad: `A Facebook-bejelentkezés pillanatnyilag nem elérhető.`,
  iforgot: `Elfelejtettem a jelszavam`,
  register: `Regisztrálok`,
  registration: `Regisztráció`,
  login: `Bejelentkezés`,
  sendcode: `Kód küldése`,
  nosuchuser: `Nem találtunk ilyen felhasználót.`,
  codeinst: `Semmi probléma. Ha tudod az email címet amivel regisztráltál, tudunk küldeni egy visszaállító kódot.`,
  weakpass: `Ez nem elég erős jelszó.`,
  onecart: `Egyszerre csak egy kosarat lehet nyitni.`,
  valuemissing: `Kérlek töltsd ki ezt a mezőt.`,
  typemismatch: `A beírt érték helytelen.`,
  passnomatch: `A két jelszó nem egyezik.`,
  emailnomatch: `A két email cím nem egyezik.`,
  passtooshort: `Egy jó jelszó legalább 8 karakter hosszú.`,
  userexists: `Már létezik regisztráció ezzel a címmel.`,
  inactiveprice: `Nem aktív ár`,
  soldout: `Elfogyott`,
  doortime: `Kapunyitás`,
  getalltickets: `Összes jegy letöltése`,
  setsell: `Eladnám`,
  geticket: `Jegy letöltése`,
  olderorders: `Régebbi vásárlások`,
  noticketsyet: `Nincsenek még jegyeid.`,
  order: `Rendelés`,
  ordernumber: `Rendelésszám`,
  card: `Bankkártyával`,
  otpszep: `OTP SZÉP kártyával`,
  otpszepszallas: `OTP SZÉP kártyával`,
  mkbszep: `MBH SZÉP kártyával`,
  mkbszepszallas: `MBH SZÉP kártyával`,
  knhszep: `K&H SZÉP kártyával`,
  accountcredit: `TIXA egyenlegemmel`,
  availablebalance: `Elérhető egyenleg`,
  insufficientbalance: `Az egyenleg nem elég a kosár tartalmához!`,
  willusepartialbalance: `Az egyenleggel csökkentettük a végleges árat.`,
  forsale: `Eladó jegyek`,
  error: `Hiba`,
  waiterror: `Sajnos nem tudtuk teljesíteni a kérésed.`,
  waitsoldout: `Sajnos elfogyott ez a jegytípus.`,
  queuesoldout: `Sajnos elfogytak ezek az árú jegyek.`,
  cartsoldout: `Sajnos már nem tudunk a kiválasztott jegyekből ennyit biztosítani.`,
  hmmm: `Hmmm..`,
  couponused: `A kuponkód beváltása már folyamatban van.`,
  codeused: `Ez a kód már nem érvényes.`,
  payredirect: `Egy kis türelmet amíg átirányítunk a fizetéshez...`,
  wrongcoupon: `Ez a kuponkód nem érvényes.`,
  wrongcode: `Ez a kód nem érvényes.`,
  termspdf: `download/PaymentService_Fizetesi_tajekoztato.pdf`,
  salesoon: `Az értékesítés még nem indult el, nézz vissza hamarosan!`,
  salecodeonly: `A jegyek csak kóddal elérhetők.`,
  salesoldout: `Nálunk sajnos elfogytak az eseményre a jegyek.`,
  saleclosed: `Az értékesítés nálunk lezárult.`,
  saleunavailable: `Az eseményre jelenleg nem lehet jegyet kapni.`,
  welcomeback: `Üdv újra itt!`,
  logout: `Kijelentkezés`,
  myprofile: `Profilom`,
  free: `Ingyenes`,
  issueticket: `Jegy kiállítása`,
  issuetickets: `Jegyek kiállítása`,
  ticketsendaddress: `Ide küldjük a kiállított jegyeket:`,
  parcels: `Parcellák`,
  tents: `Sátorjegyek`,
  accept: `Elfogadom`,
  alternatewaiting: `Most egy virtuális sorban állsz, mert az általad választott jegytípusból limitált mennyiség áll rendelkezésre.`,
  alternatewaitingmultiple: `Most egy virtuális sorban állsz, mert az általad választott jegytípusokból limitált mennyiség áll rendelkezésre.`,
  alternatewaitingdesc: `Ha a sorban előtted állók közül valaki eláll a vásárlástól, elérhetővé tesszük számodra a fennmaradó jegy(ek)et. Ez lehet, hogy kevesebb lesz, mint amennyit bekosaraztál.`,
  alternatewaitingmax: `Összes eladható jegy`,
  alternate: `Az alábbi jegytípusból már nem elérhető a kért mennyiség.`,
  alternatedesc: `Válassz a megjelenő lehetőségekből.`,
  alternatemultiple: `Az alábbi jegytípusokból már nem elérhető a kért mennyiség.`,
  alternatemultipledesc: `Válassz a jegytípusok alatt megjelenő lehetőségekből.`,
  alternatechoiceerror: `Minden jegytípusnál kötelező a választás.`,
  alternatenumberoftickets: `db.`,
  testalert: `🚧 Teszt környezet, fejlesztés alatt!`,
  seasonpass: `Season Pass`,
  followus: `Kövess minket`,
  dynamicpricing: `Dinamikus árazás`,
  dynamicpricingdesc: `A jegyárak kereslet szerint változhatnak.`,
  dynamicpricingtime: `A kosár % percig érvényes.`,
  dynamicpricingexpired: `Az ajánlatok lejártak. Kattints az újratöltésre a friss ajánlatok megjelenítéséhez.`,
  refundtickets: `Jegyvisszatérítés / módosítás`,
  refundstep1: `A kezdéshez add meg a rendeléshez használt email címet és a rendelés számát. A címre elküldünk emailben egy megerősítő kódot mellyel kiválaszthatod a visszatérítendő / módosítandó jegyeidet.`,
  refundconfirm: `Megerősítő email küldése`,
  refundnotickets: `A megadott adatokkal nem található visszatéríthető megrendelés.`,
  refundalreadyrequested: `A megadott megrendelésszámmal már be lett küldve egy visszatérítési igény.`,
  refundstep2: `Elküldtük az email címedre a visszatérítéshez / jegyeid módosításához / egyéb igény leadásához használható kódot. Kattints a linkre az emailben, vagy add meg itt:`,
  refundconfirmcode: `Megerősítő kód`,
  refundconfirmbutton: `Megerősítés`,
  refundcantconfirm: `A megadott adatokkal nem található visszatérítési kérés.`,
  refundstep3: `Válassz jegyenként a szervező által felajánlott lehetőségek közül, a legördülő menük segítségével.`,
  unknownprice: `Ismeretlen ár`,
  amounttorefund: `Visszatérítendő összeg`,
  requestrefund: `Rendelkezem a jegyeimről`,
  refundcantprocess: `Nem dolgozható fel visszatérítési igénylés.`,
  refundsuccess: `Sikeres visszatérítési / módosítási igénylés`,
  refundsuccessdescription: `Hamarosan megkezdjük igénylésed feldolgozását. A folyamat további részéről e-mailben fogunk értesíteni. Kérjük vedd figyelembe, hogy az ügyfélszolgálatunk és banki partnerünk leterheltségétől függően, illetve a szervező által megadott szabályok alapján a folyamat hosszabb időt is igénybe vehet.`,
  refundid: `Visszatérítési kérés azonosítója`,
  refundorderid: `Rendelésszám (a vásárláskor kapott e-mail tárgyában találod!)`,
  refundnochanges: `Az igény beküldése után nincs lehetőség módosításra, ezért jól gondold meg, melyik jeggyel kapcsolatban mit szeretnél tenni! A folyamat további részéről e-mailben fogunk értesíteni.`,
  refundoptionkeep: `Megtartom`,
  refundoptionvoucher: `Jövőre átviszem / Voucher-t kérek`,
  refundoptionrefund: `Visszatérítést kérek`,
  refundoptionexchange: `Másik jegyre váltom`,
  refundselectoption: `Válassz egy lehetőséget...`,
  refundshippingaddress: `Szállítási cím`,
  refundshippingdescription: `Kérlek add meg a postai címedet ahova a kiválasztott termékeket elküldhetjük.`,
  refundchoice: `Választás`,
  refundoverview: `Áttekintés és beküldés`,
  refundchange: `Változtatok valamin`,
  refundreview: `Nézd át, hogy biztosan mindent jól választottál ki.`,
  handlingfee: `Kezelési költség`,
  discount: `Kedvezmény`,
  redeemedCoupon: `Beváltott kuponkód:`,
  altoption: `Alternatív ajánlat`,
  altoptiondescription: `Lehetőséged van az eredetileg kiválasztott jegyed helyett a kékkel jelölt alternatív ajánlatot választani. Szeretnénk fehívni a figyelmed, hogy ennek ára eltérhet az eredetileg választott jegye(i)dtől!`,
  altoptiondescriptionmultiple: `Lehetőséged van az eredetileg kiválasztott jegyed helyett a kékkel jelölt alternatív ajánlatot választani. Szeretnénk fehívni a figyelmed, hogy ennek ára eltérhet az eredetileg választott jegye(i)dtől!`,
  altoptionoriginal: `Igényelt mennyiség`,
  altoptionmax: `Megveszem a teljesíthető maximumot:`,
  altoptionchange: `Lecserélem a jegyet`,
  altoptionchangemultiple: `Lecserélem a jegyeket`,
  altoptionchangeto: `Új jegy:`,
  altoptionchangeoldprice: `Eredeti jegyár:`,
  altoptionchangenewprice: `Új jegyár:`,
  altoptionchangenewqunatity: `Új mennyiség:`,
  altoptionchangeprice: `Jegyár`,
  altoptionchangetype: `Jegytípus`,
  altoptionremove: `Eltávolítom ezeket a jegyeket a kosárból`,
  altoptionchangenotice: `Jegytípus váltáskor előfordulhat, hogy változik a kezelési költség.`,
  altoptionchangeoverviewnotice: `A rendelésedet még áttekintheted majd mielőtt véglegesítenéd.`,
  continue: `Folytatás`,
  cart: `Kosár`,
  haveaccount: `Már van TIXA fiókom`,
  donthaveaccount: `Nincs TIXA fiókom`,
  registernewaccount: `Regisztrálok egy új fiókot`,
  continuewithoutaccount: `Folytatás regisztráció nélkül`,
  invoiceindividualsonlydisclaimer: `A "kisadózó vállalkozók tételes adójáról" (KATA) 2022. július 18. napján bevezetett 2022. évi XIII. törvény alapján erre a rendezvényre csak magánszemély részére tudunk számlát kiállítani.`,
  downloadpdf: `PDF letöltése`,
  downloadwallet: `Letöltés digitális tárcába`,
  orderref: `Rendelés`,
  countrylabel: `Ország`,
  cartFormEmail: `Email cím megadása kötelező`,
  cartFormEmailFormat: `Helytelen email cím`,
  cartFormName: `Név megadása kötelező`,
  cartFormPhone: `Telefonszám megadása kötelező`,
  cartFormZip: `Irányítószám megadása kötelező`,
  cartFormCity: `Település megadása kötelező`,
  cartFormAddress: `Cím megadása kötelező`,
  cartFormInvoiceName: `Számlázási név megadása kötelező`,
  cartFormInvoiceVat: `Adószám megadása kötelező`,
  cartFormInvoiceZip: `Számlázási irányítószám megadása kötelező`,
  cartFormInvoiceCity: `Számlázási település megadása kötelező`,
  cartFormInvoiceAddress: `Számlázási cím megadása kötelező`,
  cartFormTermAcceptCheckbox: `A megrendeléshez a feltételek elfogadása kötelező`,
  requiredCartField: `Kötelező mező`,
  requiredCheckbox: `Elfogadás kötelező`,
  cartFormInputLength: `Legalább 3 karakter hosszú legyen`
};
export { hu }